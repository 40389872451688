exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bookings-[id]-tsx": () => import("./../../../src/pages/bookings/[id].tsx" /* webpackChunkName: "component---src-pages-bookings-[id]-tsx" */),
  "component---src-pages-bookings-index-tsx": () => import("./../../../src/pages/bookings/index.tsx" /* webpackChunkName: "component---src-pages-bookings-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invoices-[id]-tsx": () => import("./../../../src/pages/invoices/[id].tsx" /* webpackChunkName: "component---src-pages-invoices-[id]-tsx" */),
  "component---src-pages-invoices-create-tsx": () => import("./../../../src/pages/invoices/create.tsx" /* webpackChunkName: "component---src-pages-invoices-create-tsx" */),
  "component---src-pages-invoices-index-tsx": () => import("./../../../src/pages/invoices/index.tsx" /* webpackChunkName: "component---src-pages-invoices-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-todos-index-tsx": () => import("./../../../src/pages/todos/index.tsx" /* webpackChunkName: "component---src-pages-todos-index-tsx" */),
  "component---src-pages-users-[id]-tsx": () => import("./../../../src/pages/users/[id].tsx" /* webpackChunkName: "component---src-pages-users-[id]-tsx" */),
  "component---src-pages-users-index-tsx": () => import("./../../../src/pages/users/index.tsx" /* webpackChunkName: "component---src-pages-users-index-tsx" */),
  "component---src-pages-vehicles-[id]-tsx": () => import("./../../../src/pages/vehicles/[id].tsx" /* webpackChunkName: "component---src-pages-vehicles-[id]-tsx" */),
  "component---src-pages-vehicles-create-tsx": () => import("./../../../src/pages/vehicles/create.tsx" /* webpackChunkName: "component---src-pages-vehicles-create-tsx" */),
  "component---src-pages-vehicles-index-tsx": () => import("./../../../src/pages/vehicles/index.tsx" /* webpackChunkName: "component---src-pages-vehicles-index-tsx" */)
}

