import React, { FC, ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { Toaster } from 'shadcn/sonner';
import { Icon } from 'shadcn/icon';
import { ThemeProvider } from 'shadcn/theme';

interface AppProps {
  children: ReactNode;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
    },
  },
});

// TODO: Implement ErrorBoundary
const App: FC<AppProps> = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider>
      <div>{children}</div>
      <Toaster
        expand
        closeButton
        position='bottom-center'
        icons={{
          success: <Icon name='CheckCircle' className='text-green-400' />,
          error: <Icon name='AlertOctagon' className='text-red-400' />,
        }}
      />
      <ReactQueryDevtools />
    </ThemeProvider>
  </QueryClientProvider>
);

export default App;
